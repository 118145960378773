import { Box } from 'components/Box/Box';
import { Icon } from 'components/Icon/Icon';
import { CmsApiS3FileLink } from 'modules/cms/api/types/CmsApiLink';
import { useCmsDownloadPermissions } from 'modules/cms/hooks/useCmsDownloadPermissions';

import { CmsButtonRender } from './types/CmsButtonRender';

type Props = {
  buttonData: CmsApiS3FileLink;
  eventProperties: Record<string, unknown>;
  render: CmsButtonRender;
};

export function CmsDownloadButton({
  buttonData,
  eventProperties,
  render,
}: Props) {
  const { onClick, href, download } = useCmsDownloadPermissions({
    buttonData,
    eventName: 'CMS Block',
    eventProperties,
  });

  return render({
    data: href
      ? { type: 'link', nativeAnchorTag: true, onClick, href }
      : { type: 'button', onClick },
    children: (
      <Box display="flex" alignItems="center">
        {download && <Icon name="download" size={18} />}
        <Box ml="8px">
          {download ? `  ${buttonData.text}` : buttonData.text}
        </Box>
      </Box>
    ),
    'data-qa-id': 'cms-download-button-link',
  });
}
